import { template as template_2f3c501b409c467b8692fe01e4d9c4f6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_2f3c501b409c467b8692fe01e4d9c4f6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
