import { template as template_b46420bc5d2f49fb94365f510ab7026f } from "@ember/template-compiler";
const FKControlMenuContainer = template_b46420bc5d2f49fb94365f510ab7026f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
