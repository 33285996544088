import { template as template_d4087b35d6874223a84eb2fade43d2d1 } from "@ember/template-compiler";
const FKLabel = template_d4087b35d6874223a84eb2fade43d2d1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
