import { template as template_82be864999424352b95ea9eee3438981 } from "@ember/template-compiler";
const SidebarSectionMessage = template_82be864999424352b95ea9eee3438981(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
