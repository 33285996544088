import { template as template_cd2d2f83e2764ca39c185b2b7978c593 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_cd2d2f83e2764ca39c185b2b7978c593(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
