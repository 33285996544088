import { template as template_b4e97c6bc6714076ba946641e8c41f03 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_b4e97c6bc6714076ba946641e8c41f03(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
